$primaryColor: #afafbf;
$secondaryColor: #e5e5e5;
$emphasiseColor: #21e6c1;
$primaryBackground: #071e3d;
$secondaryBackground: #1f4287;
$emphasiseBackground: #203550;

$bp-medium-phone: 23em; // 368px/16px = 23em
$bp-tablet: 37.5em; // 600px/16px = 37.5em
$bp-small-laptop: 56.25em; // 900px/16px = 37.5em
