@import "../../variable.module.scss";

.skillCard {
  flex: 1;
  text-transform: capitalize;
  background: $secondaryBackground;
  text-align: center;
  padding: 1rem 0;
  margin: 1rem 0;
  border-radius: 5px;
  // box-shadow: 0 0 0.5rem $primaryColor;
  transition: transform, 0.2s ease-in-out;

  &:hover {
    transform: translateY(-1rem);
  }

  & .title {
    margin-bottom: 2rem;
    color: $emphasiseColor;
  }

  & .item {
    color: $secondaryColor;
    padding: 0.1rem 0;
  }
}
@media (min-width: $bp-tablet) {
  .skillCard:not(:last-child) {
    margin-right: 1.5rem;
  }
}
