@import '../../variable.module.scss';

.projects {
  & .sectionHeader {
    // text-align: center;
    color: $emphasiseColor;
    margin-bottom: 3rem;
    // &:before,
    &:after {
      display: inline-block;
      content: '';
      vertical-align: middle;
      background: $primaryColor;
      width: 20%;
      height: 1px;
      margin: 1rem;
    }
  }
  & .project {
    padding-bottom: 2rem;
    & img {
      width: 100%;
      padding-bottom: 2rem;
    }
    & .textDiv {
      padding-bottom: 2rem;
      ul {
        padding: 1rem 5rem;
      }
    }
    & .linkDiv {
      display: flex;
      a {
        margin-right: 2rem;
        display: flex;
        justify-content: center;
        align-items: center;
        &:hover {
          color: $emphasiseColor;
        }
      }
    }
  }
  @media (min-width: $bp-tablet) {
    .project {
      // display: flex;
      display: grid;
      align-items: center;
      grid-template-columns: 1fr 1fr;
      column-gap: 3rem;
    }
  }
}
