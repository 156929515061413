@import "../../variable.module.scss";

.aboutSection {
  // margin-bottom: 25rem;
  & h2 {
    color: $emphasiseColor;
    margin-bottom: 3rem;
    &:after {
      display: inline-block;
      content: "";
      vertical-align: middle;
      background: $primaryColor;
      width: 20%;
      height: 1px;
      margin: 1rem;
    }
  }
  & p {
    margin-bottom: 1rem;
  }
  & .aboutPara {
    margin-bottom: 2rem;
  }
  & .cards {
    display: flex;
    flex-direction: column;
    // justify-content: space-evenly;
  }
  @media (min-width: $bp-tablet) {
    & .cards {
      flex-direction: row;
    }
  }
}

.icon {
  font-size: 4rem;
  margin-bottom: 0.3rem;
}

// @media (min-width: $bp-tablet) {
//   .aboutSection .cards {
//     flex-direction: row;
//   }
// }
