@import './variable.module.scss';

.linkHighlight {
  padding: 0.5rem 1rem;
  color: $emphasiseColor;
  border: $emphasiseColor solid 1px;
  &:hover {
    background: rgba($color: $emphasiseColor, $alpha: 0.2);
  }
}

.sectionHeader {
  color: $emphasiseColor;
  margin-bottom: 3rem;

  &:after {
    display: inline-block;
    content: '';
    vertical-align: middle;
    background: $primaryColor;
    width: 20%;
    height: 1px;
    margin: 1rem;
  }
}
