@import url(https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap);
.Navbar_navContainer__3tWRU{position:-webkit-sticky;position:sticky;top:0;left:0;z-index:999;background:#071e3d;width:100%;padding:2rem 3rem;display:flex;align-items:center;transition:all 0.4s ease-in}.Navbar_navContainer__3tWRU .Navbar_logo__46AHD{width:3rem}.Navbar_navContainer__3tWRU ul{list-style:none;margin-left:auto;display:none}@media (min-width: 37.5em){.Navbar_navContainer__3tWRU ul{display:flex}}.Navbar_navContainer__3tWRU ul .Navbar_navlink__1EXTg{padding:0.5rem 1rem}.Navbar_navContainer__3tWRU ul .Navbar_navlink__1EXTg:hover{color:#21e6c1}.Navbar_resume__2lcgq{margin-left:auto}@media (min-width: 37.5em){.Navbar_resume__2lcgq{margin-left:0.5rem}}.Navbar_navBtn__3RKCb{background:none;border:none;margin-left:1.5rem}.Navbar_navBtn__3RKCb .Navbar_barIcon__2tnSx{color:#afafbf;font-size:2.5rem}@media (min-width: 37.5em){.Navbar_navBtn__3RKCb{display:none}}.Navbar_navContainerHide__3gzX4{transform:translateY(-16rem)}.Navbar_navContainerBoxShadow__mjPSS{box-shadow:0 5px 20px rgba(0,0,0,0.5);background:#203550}.Navbar_navDropDown__3Djpc{position:absolute;font-size:2.4rem;top:0;z-index:1;width:100%;height:100%;display:flex;flex-direction:column;justify-content:center;align-items:center;list-style:none;background:#1f4287;transition:transform 0.5s ease-in-out}.Navbar_navDropDown__3Djpc a{color:#21e6c1;padding:1rem}.Navbar_show__1PlMz{transform:translateY(0)}.Navbar_hide__1iOzX{transform:translateY(-100vh)}

.Style_linkHighlight__1StR5{padding:0.5rem 1rem;color:#21e6c1;border:#21e6c1 solid 1px}.Style_linkHighlight__1StR5:hover{background:rgba(33,230,193,0.2)}.Style_sectionHeader__1e95m{color:#21e6c1;margin-bottom:3rem}.Style_sectionHeader__1e95m:after{display:inline-block;content:'';vertical-align:middle;background:#afafbf;width:20%;height:1px;margin:1rem}

.Layout_layout__vps_j{max-width:1100px;margin:auto}

.Hero_hero___KI-N{margin-top:25vh;text-transform:capitalize}@media (min-width: 37.5em){.Hero_hero___KI-N{display:flex;margin-top:0}}.Hero_hero___KI-N .Hero_heroText__aIlL6{display:flex;flex-direction:column;justify-content:center}.Hero_hero___KI-N .Hero_heroText__aIlL6 div{margin-bottom:2rem}.Hero_hero___KI-N .Hero_heroText__aIlL6 a{font-size:2rem}.Hero_hero___KI-N .Hero_illustrationImg__2jv5Z{display:none}@media (min-width: 37.5em){.Hero_hero___KI-N .Hero_illustrationImg__2jv5Z{display:inline;width:40%;margin-left:auto}}.Hero_emphasiseColor__3L0c3{color:#21e6c1}.Hero_secondaryColor__27FIN{color:#e5e5e5}

.SkillCard_skillCard__1HC8V{flex:1 1;text-transform:capitalize;background:#1f4287;text-align:center;padding:1rem 0;margin:1rem 0;border-radius:5px;transition:transform, 0.2s ease-in-out}.SkillCard_skillCard__1HC8V:hover{transform:translateY(-1rem)}.SkillCard_skillCard__1HC8V .SkillCard_title__3tUnm{margin-bottom:2rem;color:#21e6c1}.SkillCard_skillCard__1HC8V .SkillCard_item__1zNQL{color:#e5e5e5;padding:0.1rem 0}@media (min-width: 37.5em){.SkillCard_skillCard__1HC8V:not(:last-child){margin-right:1.5rem}}

.About_aboutSection__2cYdD h2{color:#21e6c1;margin-bottom:3rem}.About_aboutSection__2cYdD h2:after{display:inline-block;content:"";vertical-align:middle;background:#afafbf;width:20%;height:1px;margin:1rem}.About_aboutSection__2cYdD p{margin-bottom:1rem}.About_aboutSection__2cYdD .About_aboutPara__2KcII{margin-bottom:2rem}.About_aboutSection__2cYdD .About_cards__1CwjG{display:flex;flex-direction:column}@media (min-width: 37.5em){.About_aboutSection__2cYdD .About_cards__1CwjG{flex-direction:row}}.About_icon__i1fQh{font-size:4rem;margin-bottom:0.3rem}

.Projects_projects__3hCFj .Projects_sectionHeader__3rfpO{color:#21e6c1;margin-bottom:3rem}.Projects_projects__3hCFj .Projects_sectionHeader__3rfpO:after{display:inline-block;content:'';vertical-align:middle;background:#afafbf;width:20%;height:1px;margin:1rem}.Projects_projects__3hCFj .Projects_project__1dlEA{padding-bottom:2rem}.Projects_projects__3hCFj .Projects_project__1dlEA img{width:100%;padding-bottom:2rem}.Projects_projects__3hCFj .Projects_project__1dlEA .Projects_textDiv__pdZ5Q{padding-bottom:2rem}.Projects_projects__3hCFj .Projects_project__1dlEA .Projects_textDiv__pdZ5Q ul{padding:1rem 5rem}.Projects_projects__3hCFj .Projects_project__1dlEA .Projects_linkDiv__L5Zuw{display:flex}.Projects_projects__3hCFj .Projects_project__1dlEA .Projects_linkDiv__L5Zuw a{margin-right:2rem;display:flex;justify-content:center;align-items:center}.Projects_projects__3hCFj .Projects_project__1dlEA .Projects_linkDiv__L5Zuw a:hover{color:#21e6c1}@media (min-width: 37.5em){.Projects_projects__3hCFj .Projects_project__1dlEA{display:grid;align-items:center;grid-template-columns:1fr 1fr;grid-column-gap:3rem;-webkit-column-gap:3rem;column-gap:3rem}}

.project_textDiv__2Qdq0{padding-bottom:2rem}.project_textDiv__2Qdq0 ul{padding:1rem 5rem}.project_linkDiv__3hckx{display:flex}.project_linkDiv__3hckx a{margin-right:2rem;display:flex;justify-content:center;align-items:center}.project_linkDiv__3hckx a:hover{color:#21e6c1}.project_linkDiv__3hckx a .project_icon__4ALOr{margin-right:0.5rem}

.Contact_contactSection__2PaKu p{margin-bottom:3rem}.Contact_icon__1rC_M{vertical-align:top}

*,*::before,*::after{margin:0;padding:0;box-sizing:inherit}html{font-size:62.5%}body{font-family:"Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", sans-serif;font-size:1.6rem;-webkit-font-smoothing:antialiased;-moz-osx-font-smoothing:grayscale;box-sizing:border-box}a{text-decoration:none;color:#afafbf;display:inline-block;text-transform:capitalize;border-radius:3px;cursor:pointer}h1{font-size:3.2rem;font-weight:300;text-transform:capitalize}h2{font-size:2.4rem;font-weight:300}h3{font-size:1.87rem;font-weight:300}section{margin:0 3rem;padding-top:2rem;margin-bottom:8rem}p{text-align:justify}.App_App__15LM-{display:flex;flex-direction:column;color:#afafbf;background-color:#071e3d;height:100vh;overflow-y:scroll;scroll-behavior:smooth}@media only screen and (min-width: 23em){html{font-size:70%}}

.Footer_footerSection__17poW{padding:1rem 0;background:#203550;text-align:center}.Footer_footerSection__17poW .Footer_bottom__1H1Wj{margin-bottom:1rem}.Footer_footerSection__17poW p{text-align:center}.Footer_footerSection__17poW a{padding:0 1rem}

