@import "../../variable.module.scss";

.layout {
  max-width: 1100px;
  // margin-top: 7rem;
  margin: auto;
  // margin-top: 0;
  // transform: translateY(-5rem);
  // border: 1px solid green;

  // padding: $layoutPadding;
}
