@import '../../variable.module.scss';

.navContainer {
  position: sticky;
  top: 0;
  left: 0;
  z-index: 999;
  background: $primaryBackground;
  width: 100%;
  padding: 2rem 3rem;
  display: flex;
  // justify-content: space-between;
  align-items: center;
  transition: all 0.4s ease-in;
  // border: 1px red solid;

  & .logo {
    width: 3rem;
  }

  & ul {
    list-style: none;
    margin-left: auto;
    display: none;
    @media (min-width: $bp-tablet) {
      display: flex;
    }
    & .navlink {
      padding: 0.5rem 1rem;
      &:hover {
        color: $emphasiseColor;
      }
    }
  }
}

.resume {
  margin-left: auto;
}
@media (min-width: $bp-tablet) {
  .resume {
    margin-left: 0.5rem;
  }
}

.navBtn {
  background: none;
  border: none;
  margin-left: 1.5rem;
  & .barIcon {
    color: $primaryColor;
    font-size: 2.5rem;
  }
}

@media (min-width: $bp-tablet) {
  .navBtn {
    display: none;
  }
}
.navContainerHide {
  // this rule is for scolling effect
  transform: translateY(-16rem);
}

.navContainerBoxShadow {
  // this rule is for scrolling effect
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.5);
  background: $emphasiseBackground;
}

.navDropDown {
  position: absolute;
  font-size: 2.4rem;
  top: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  list-style: none;
  background: $secondaryBackground;
  transition: transform 0.5s ease-in-out;
  & a {
    color: $emphasiseColor;
    padding: 1rem;
  }
}

.show {
  transform: translateY(0);
}
.hide {
  transform: translateY(-100vh);
}
