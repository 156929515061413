@import '../../../variable.module.scss';

.textDiv {
  padding-bottom: 2rem;
  ul {
    padding: 1rem 5rem;
  }
}
.linkDiv {
  display: flex;
  a {
    margin-right: 2rem;
    display: flex;
    justify-content: center;
    align-items: center;
    &:hover {
      color: $emphasiseColor;
    }
    & .icon {
      margin-right: 0.5rem;
    }
  }
}
