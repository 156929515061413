@import '../../variable.module.scss';

.footerSection {
  padding: 1rem 0;
  background: $emphasiseBackground;
  text-align: center;
  & .bottom {
    margin-bottom: 1rem;
  }
  p {
    text-align: center;
  }
  a {
    padding: 0 1rem;
  }
}
