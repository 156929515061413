@import "./variable.module.scss";
@import url("https://fonts.googleapis.com/css?family=Roboto:300,400&display=swap");

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit;
}

html {
  font-size: 62.5%;
}

body {
  font-family: "Roboto", -apple-system, BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  font-size: 1.6rem;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
}

a {
  text-decoration: none;
  color: $primaryColor;
  display: inline-block;
  text-transform: capitalize;
  border-radius: 3px;
  cursor: pointer;
}
h1 {
  font-size: 3.2rem;
  font-weight: 300;
  text-transform: capitalize;
}

h2 {
  font-size: 2.4rem;
  font-weight: 300;
}

h3 {
  font-size: 1.87rem;
  font-weight: 300;
}

section {
  margin: 0 3rem;
  padding-top: 2rem;
  margin-bottom: 8rem;
  // min-height: 80vh;
  // align-content: center;
}
p {
  text-align: justify;
}

// h4 {
//   font-size: 2rem;
//   font-weight: 300;
// }
/* end of globals */

.App {
  display: flex;
  flex-direction: column;
  color: $primaryColor;
  background-color: $primaryBackground;
  height: 100vh;
  overflow-y: scroll;
  scroll-behavior: smooth;
}

@media only screen and (min-width: $bp-medium-phone) {
  html {
    font-size: 70%;
  }
}

// @media only screen and (min-width: $bp-tablet) {
//   html {
//     font-size: 82%;
//   }
// }
// @media only screen and (min-width: 800px) {
//   html {
//     font-size: 87.5%;
//   }
// }
// @media only screen and (min-width: 1000px) {
//   html {
//     font-size: 93%;
//   }
// section {
//   margin: 2rem auto;
// }
// }

// @media only screen and (min-width: 1200px) {
//   html {
//     font-size: 100%;
//   }
// }

// @media only screen and (min-width: 1400px) {
//   html {
//     font-size: 120%;
//   }
// }
