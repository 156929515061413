@import "../../variable.module.scss";

.contactSection {
  & p {
    margin-bottom: 3rem;
  }
}

.icon {
  vertical-align: top;
}
