@import "../../variable.module.scss";

.hero {
  // height: 80vh;
  margin-top: 25vh;
  text-transform: capitalize;
  // border: solid 1px pink;
  @media (min-width: $bp-tablet) {
    display: flex;

    margin-top: 0;
    // display: grid;
    // grid-template-columns: 2fr 1fr;
    // padding: 0;
    // align-items: center;
  }
  // justify-content: space-between;
  // align-content: center;
  & .heroText {
    display: flex;
    flex-direction: column;
    justify-content: center;
    & div {
      margin-bottom: 2rem;
    }
    & a {
      font-size: 2rem;
    }
  }
  & .illustrationImg {
    display: none;
    @media (min-width: $bp-tablet) {
      display: inline;
      width: 40%;
      margin-left: auto;
      // align-self: center;
      // padding-bottom: 55%;
    }
    // align-self: center;
    // border: 1px solid red;
    // height: 11.5rem;
  }
}

.emphasiseColor {
  color: $emphasiseColor;
}
.secondaryColor {
  color: $secondaryColor;
}
